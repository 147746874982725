<template>
  <div>
    <ion-card class="login-result">
      <ion-card-content v-if="status=='success'">
          <ion-icon color="success" :ios="checkmarkCircle" :md="checkmarkCircle" class="big-circle-checkmark"/>
          <h1>
            Dexcom autharization was succesful.
          </h1>
      </ion-card-content>
      <ion-card-content v-else>
          <ion-icon color="danger" :ios="alertCircle" :md="alertCircle" class="big-circle-alert"/>
          <h1>
            Dexcom authorization was unsuccessful. <br/>
            Please retry from the Loop app.
          </h1>
      </ion-card-content>
    </ion-card>
    <ion-button class="go-back-button" :href="loopLink">
      <ion-icon slot="start" :ios="chevronBackOutline" :md="chevronBackOutline"/>
      Go back to the app
    </ion-button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { checkmarkCircle, alertCircle, chevronBackOutline } from 'ionicons/icons'
import {
  IonIcon,
  IonCard,
  IonCardContent,
  IonButton,
} from '@ionic/vue'


export default defineComponent({
  name: 'DexcomLoginReturn',
  setup () {
    return {checkmarkCircle, alertCircle, chevronBackOutline}
  },
  components: {
    IonIcon,
    IonCard,
    IonCardContent,
    IonButton,
  },
  computed: {
    loopLink () {
      return `${process.env.VUE_APP_APP_HOST}/profile`
    },
    status () {
      return this.$route.query.status
    },
    code () {
      return this.$route.query.code
    },
  },
})
</script>

<style scoped>
.go-back-button {
  position: absolute;
  bottom: 90px;
  left: 25px;
  width: calc(100% - 50px);
  height: 48px;
  margin: 0;
}
.big-circle-checkmark {
  margin-left: auto;
  margin-right: auto;
  display: block;
  zoom: 5.0;
}
.big-circle-alert {
  margin-left: auto;
  margin-right: auto;
  display: block;
  zoom: 5.0;
}
.login-result {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
